import React from 'react'
import {Link} from 'react-router-dom'
import {cx} from '@linaria/core'

import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'
import urls from '@daedalus/core/src/utils/url'

import {Button} from '../Button'
import {Divider} from '../Divider'
import {Icon} from '../Icon'
import {
  BackButtonElement,
  CenterContentWrapper,
  EndContentWrapper,
  HeaderElement,
  HeaderTopContentElement,
  logoStyles,
  Spacer
} from './styles'

type OnButtonClick = (e: React.SyntheticEvent<Element, Event>) => void

type BrandLogoComponent = React.FunctionComponent<
  {
    brand: Brand
    compact?: boolean
    withIconText?: boolean
  } & React.ImgHTMLAttributes<HTMLImageElement>
>

interface Props {
  /** Brand configuration. It can be one of our brands or a white-label brand. */
  brand: Brand
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Which url should the logo send the user when clicked upon */
  logoUrl?: string
  /** Whether the logo icon should be disabled */
  disableLogoClick?: boolean
  /** Whether to show the a center positioned logo */
  showCenterLogo?: boolean
  /** Whether to show the a start positioned logo */
  showStartLogo?: boolean
  /** Whether to show the top header content */
  showTopContent?: boolean
  /** Whether to display a bottom shadow */
  hasShadow?: boolean
  /** Whether to display a bottom divider */
  hasDivider?: boolean
  /** Whether to expand center content and use end content slot for it */
  hasExpandedCenterContent?: boolean
  /** Custom content (element or text) to render in the center of the header */
  centerContent?: JSX.Element | string
  /** Custom content to be displayed on the end with priority */
  endContent?: JSX.Element
  /** Custom content to be displayed on the bottom with priority */
  bottomContent?: JSX.Element | null
  /** Custom Icon for back button */
  backButtonIcon?: React.ReactNode
  /** Callback for BackButton click. BackButton is hidden if callback is not passed */
  onBackButtonClick?: OnButtonClick
  /** Callback for SettingsButton click. SettingsButton is hidden if callback is not passed */
  onSettingsButtonClick?: OnButtonClick
  /** Custom Logo component */
  BrandLogoComponent: BrandLogoComponent
  /** Flag determines if top header background must be filled with brand color (white by default) */
  hasBrandBackground?: boolean
}

interface LogoProps {
  brand: Brand
  logoUrl?: string
  disableLogoClick?: boolean
  BrandLogoComponent: BrandLogoComponent
}

interface DisplayLogoElementProps extends LogoProps {
  compact?: boolean
}

interface BackButtonProps {
  onBackButtonClick?: OnButtonClick
  backButtonIcon: React.ReactNode
}

interface StartContentProps {
  brand: Brand
  hasCenterContent: boolean
  showCenterLogo: boolean
  showStartLogo: boolean
  logoUrl?: string
  disableLogoClick?: boolean
  onBackButtonClick?: OnButtonClick
  backButtonIcon: React.ReactNode
  isFullWidth: boolean
  BrandLogoComponent: BrandLogoComponent
}

interface DefaultEndContentProps {
  onSettingsButtonClick?: OnButtonClick
}

interface DefaultCenterContentProps {
  brand: Brand
  logoUrl?: string
  showCenterLogo?: boolean
  disableLogoClick?: boolean
  BrandLogoComponent: BrandLogoComponent
}

const DisplayLogoElement = ({
  compact,
  disableLogoClick,
  brand,
  logoUrl,
  BrandLogoComponent
}: DisplayLogoElementProps) => {
  const brandIsVio = checkIsBrandVio(brand)
  if (disableLogoClick) {
    return (
      <div
        data-id="BrandLogo"
        className={cx(logoStyles, compact && '--compact')}
      >
        <BrandLogoComponent
          compact={compact}
          brand={brand}
          withIconText={brandIsVio}
          height={compact ? 'auto' : undefined}
        />
      </div>
    )
  }

  // some partners want a custom logo url, so we check for that first
  const redirectUrl = brand.siteAddresses.logoRedirectUrl || logoUrl

  if (brand.siteAddresses.logoRedirectUrl || logoUrl) {
    return (
      <a
        data-id="BrandLogo"
        className={cx(logoStyles, compact && '--compact')}
        href={redirectUrl}
      >
        <BrandLogoComponent
          compact={compact}
          brand={brand}
          withIconText={brandIsVio}
          height={compact ? 'auto' : undefined}
        />
      </a>
    )
  }

  return (
    <Link
      data-id="BrandLogo"
      className={cx(logoStyles, compact && '--compact')}
      to={urls.root}
    >
      <BrandLogoComponent
        compact={compact}
        brand={brand}
        withIconText={brandIsVio}
        height={compact ? 'auto' : undefined}
      />
    </Link>
  )
}

// NOTE: It is important to keep FullLogo and CompactLogo as separate components, so that
// the logo changes smoothly when you click on the search box
// https://github.com/FindHotel/daedalus/pull/3253#discussion_r776666082
const FullLogo = ({
  disableLogoClick,
  brand,
  logoUrl,
  BrandLogoComponent
}: LogoProps) => {
  return (
    <DisplayLogoElement
      disableLogoClick={disableLogoClick}
      brand={brand}
      logoUrl={logoUrl}
      BrandLogoComponent={BrandLogoComponent}
    />
  )
}

const CompactLogo = ({
  disableLogoClick,
  brand,
  logoUrl,
  BrandLogoComponent
}: LogoProps) => {
  return (
    <DisplayLogoElement
      compact
      disableLogoClick={disableLogoClick}
      brand={brand}
      logoUrl={logoUrl}
      BrandLogoComponent={BrandLogoComponent}
    />
  )
}

const BackButton = ({onBackButtonClick, backButtonIcon}: BackButtonProps) => (
  <BackButtonElement
    hasTouchArea
    size="md"
    variant="quiet"
    iconStart={backButtonIcon}
    onClick={onBackButtonClick}
  />
)

const StartContent = ({
  onBackButtonClick,
  disableLogoClick,
  hasCenterContent,
  showCenterLogo,
  showStartLogo,
  brand,
  logoUrl,
  backButtonIcon,
  isFullWidth,
  BrandLogoComponent
}: StartContentProps) => {
  if (onBackButtonClick) {
    return (
      <BackButton
        backButtonIcon={backButtonIcon}
        onBackButtonClick={onBackButtonClick}
      />
    )
  }

  const showLogo = !showCenterLogo && showStartLogo

  if (showLogo && hasCenterContent) {
    return (
      <CompactLogo
        disableLogoClick={disableLogoClick}
        brand={brand}
        logoUrl={logoUrl}
        BrandLogoComponent={BrandLogoComponent}
      />
    )
  }

  if (showLogo && !hasCenterContent) {
    return (
      <FullLogo
        disableLogoClick={disableLogoClick}
        brand={brand}
        logoUrl={logoUrl}
        BrandLogoComponent={BrandLogoComponent}
      />
    )
  }

  return isFullWidth ? null : <Spacer />
}

const DefaultEndContent = ({onSettingsButtonClick}: DefaultEndContentProps) =>
  onSettingsButtonClick ? (
    <Button
      hasTouchArea
      dataId="ShowPreferencesButton"
      size="md"
      variant="quiet"
      iconStart={<Icon name="Menu" />}
      onClick={onSettingsButtonClick}
    />
  ) : (
    <Spacer />
  )

const DefaultCenterContent = ({
  showCenterLogo,
  brand,
  disableLogoClick,
  logoUrl,
  BrandLogoComponent
}: DefaultCenterContentProps) =>
  showCenterLogo ? (
    <FullLogo
      brand={brand}
      logoUrl={logoUrl}
      disableLogoClick={disableLogoClick}
      BrandLogoComponent={BrandLogoComponent}
    />
  ) : null

export const CompactHeader = ({
  brand,
  centerContent,
  endContent,
  logoUrl,
  disableLogoClick = false,
  showCenterLogo = false,
  showStartLogo = true,
  hasShadow = false,
  hasDivider = false,
  hasExpandedCenterContent = false,
  bottomContent = null,
  backButtonIcon = <Icon name={'ChevronLeft'} />,
  className,
  onBackButtonClick,
  onSettingsButtonClick,
  showTopContent = true,
  hasBrandBackground = false,
  BrandLogoComponent
}: Props) => {
  const isFullWidth = !showStartLogo && !endContent
  return (
    <>
      <HeaderElement
        className={cx(
          className,
          hasShadow && '--shadow',
          hasBrandBackground && '--hasBrandBackground'
        )}
      >
        {showTopContent && (
          <HeaderTopContentElement>
            <StartContent
              showCenterLogo={showCenterLogo}
              showStartLogo={showStartLogo}
              hasCenterContent={!!centerContent || showCenterLogo}
              disableLogoClick={disableLogoClick}
              brand={brand}
              logoUrl={logoUrl}
              onBackButtonClick={onBackButtonClick}
              backButtonIcon={backButtonIcon}
              isFullWidth={isFullWidth}
              BrandLogoComponent={BrandLogoComponent}
            />

            <CenterContentWrapper>
              {centerContent || (
                <DefaultCenterContent
                  brand={brand}
                  disableLogoClick={disableLogoClick}
                  showCenterLogo={showCenterLogo}
                  logoUrl={logoUrl}
                  BrandLogoComponent={BrandLogoComponent}
                />
              )}
            </CenterContentWrapper>

            {!hasExpandedCenterContent && (
              <EndContentWrapper>
                {endContent || (
                  <DefaultEndContent
                    onSettingsButtonClick={onSettingsButtonClick}
                  />
                )}
              </EndContentWrapper>
            )}
          </HeaderTopContentElement>
        )}
        {bottomContent}
      </HeaderElement>
      {hasDivider && <Divider />}
    </>
  )
}
