import React, {useEffect} from 'react'
import {cx} from '@linaria/core'
import {useAnimation} from 'framer-motion'
import {clamp} from 'ramda'

import {usePreviousValue} from '@daedalus/core/src/utils/react/hooks/usePreviousValue'

import {DOT_SECTION_SIZE, DOT_WIDTH} from './constants'
import {DotIndicator, IndicatorWrapper} from './styled'

interface Props {
  amount: number
  hasDarkGradient: boolean
  currentIndex: number
  width: number
  isRtl: boolean
}

const calculateDotPosition = (i: number, currentIndex: number, width: number) =>
  width / 2 -
  currentIndex * DOT_SECTION_SIZE +
  i * DOT_SECTION_SIZE -
  DOT_WIDTH / 2

const getIndicatorStyle = ({
  indicatorIndex,
  currentIndex,
  width
}: {
  indicatorIndex: number
  currentIndex: number
  width: number
}) => ({
  x: calculateDotPosition(indicatorIndex, currentIndex, width),
  scale: 1 / clamp(1, 7, Math.abs(indicatorIndex - currentIndex)),
  display: Math.abs(indicatorIndex - currentIndex) > 3 ? 'none' : 'block',
  opacity: indicatorIndex === currentIndex ? 1 : 0.5
})

export const CurrentPaneIndicators = ({
  currentIndex,
  hasDarkGradient = true,
  amount,
  width,
  isRtl
}: Props) => {
  const previousIndex = usePreviousValue(currentIndex)
  const controls = useAnimation()

  useEffect(() => {
    const immediate = Math.abs((previousIndex ?? 0) - currentIndex) > 1 // No animation when we roll over to/from 0
    controls.start((indicatorIndex: number) => ({
      ...getIndicatorStyle({indicatorIndex, currentIndex, width}),
      ...(immediate
        ? {
            transition: {
              duration: 0
            }
          }
        : {
            transition: {
              type: 'spring',
              bounce: 0
            }
          })
    }))
  }, [currentIndex, width, controls, previousIndex])

  return (
    <IndicatorWrapper className={cx(hasDarkGradient && 'hasDarkGradient')}>
      {[...new Array(amount)].map((_, indicatorIndex) => (
        <DotIndicator
          className={cx(isRtl ? 'isRTL' : 'isLTR')}
          key={indicatorIndex} // eslint-disable-line react/no-array-index-key
          custom={indicatorIndex}
          initial={getIndicatorStyle({indicatorIndex, currentIndex, width})}
          animate={controls}
        />
      ))}
    </IndicatorWrapper>
  )
}
