import React, {useCallback, useEffect, useRef, useState} from 'react'
import {cx} from '@linaria/core'
import {animate, PanInfo, Transition, useMotionValue} from 'framer-motion'

import {closeOnEscape} from '../../../../utils/closeOnEscape'
import {handleArrowKeys} from '../../../../utils/handleArrowKeys'
import {DRAG_CANCEL_DISTANCE} from './constants'
import {CurrentPaneIndicators} from './CurrentPaneIndicators'
import {PaginationButtons} from './PaginationButtons'
import {Pane, RenderPane} from './Pane'
import {Container, PanesAmount} from './styled'

// Use this to pre-render more/less invisible panes on either side
const range = [-1, 0, 1]

interface Props {
  initialIndex: number
  panesAmount: number
  width: number
  renderPane: RenderPane
  showPaginationButtons: boolean
  hasDarkGradient: boolean
  hasDarkBackground: boolean
  showPageNumber: boolean
  isZoomEnabled: boolean
  isRtl: boolean
  onSwipe?: (newPaneIndex: number) => void
  onZoom?: () => void
  showPaginationOnHover?: boolean
  onClose: () => void
}

// Do not use `bounce: 0` - it will cause skipping
const transition: Transition = {
  type: 'spring',
  mass: 1,
  damping: 40,
  stiffness: 280
}

const getPaneIndex = (index: number, offset: number, panesAmount: number) => {
  // Calculate the actual pane index with wrap over
  const modulo = (offset + index) % panesAmount
  return modulo < 0 ? panesAmount + modulo : modulo
}

const getTargetX = (index: number, width: number) => -index * (width || 0)

export const VirtualizedPane = ({
  initialIndex = 0,
  panesAmount,
  width,
  renderPane,
  showPaginationButtons,
  hasDarkGradient,
  hasDarkBackground,
  showPageNumber,
  isZoomEnabled,
  isRtl,
  onSwipe,
  onZoom,
  onClose,
  showPaginationOnHover = false
}: Props) => {
  // Index is free ranged, it can infinitely count up/down
  const [index, setIndex] = useState(initialIndex)
  const centerPaneIndex = getPaneIndex(index, 0, panesAmount)

  const targetX = getTargetX(index, width)
  const switchThreshold = Math.min(DRAG_CANCEL_DISTANCE, width / 5)

  const x = useMotionValue(targetX)

  // Using ref allows us to keep handleEndDrag stable
  const indexRef = useRef(index)
  indexRef.current = index

  // Set new index and track the swipe
  const goToPane = useCallback(
    (index: number) => {
      setIndex(index)
      window.requestAnimationFrame(() => {
        onSwipe?.(index)
      })
    },
    [onSwipe]
  )

  useEffect(() => {
    closeOnEscape({onClose})
  }, [onClose])

  useEffect(() => {
    const removeArrowKeysEventListener = handleArrowKeys({
      onLeft: () => goToPane(index - 1),
      onRight: () => goToPane(index + 1)
    })

    return () => {
      removeArrowKeysEventListener()
    }
  }, [goToPane, index])

  const handleEndDrag = useCallback(
    (e: Event, dragProps: PanInfo) => {
      const {offset, velocity} = dragProps

      const targetX = getTargetX(indexRef.current, width)

      // Swiped up/down more than sideways, cancel
      if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
        return animate(x, targetX, transition)
      }

      // Broke threshold swiping back
      if (offset.x > switchThreshold) {
        return goToPane(indexRef.current - 1)
      }

      // Broke threshold swiping forward
      if (offset.x < -switchThreshold) {
        return goToPane(indexRef.current + 1)
      }

      // No threshold reached, cancel
      animate(x, targetX, transition)
    },
    [width, x, switchThreshold, goToPane]
  )

  useEffect(() => {
    const controls = animate(x, targetX, transition)
    return controls.stop
  }, [index, targetX, x])

  return (
    <Container
      width={width}
      className={cx(showPaginationOnHover && 'showPaginationOnHover')}
    >
      {range.map(offset => (
        <Pane
          key={offset + index}
          x={x}
          index={offset + index}
          paneIndex={getPaneIndex(index, offset, panesAmount)} // Calculate the paneIndex for each pane in the range
          renderPane={renderPane}
          isZoomEnabled={isZoomEnabled}
          onDragEnd={handleEndDrag}
          onZoom={onZoom}
        />
      ))}

      {panesAmount > 1 && (
        <CurrentPaneIndicators
          currentIndex={centerPaneIndex}
          amount={panesAmount}
          hasDarkGradient={hasDarkGradient}
          width={width}
          isRtl={isRtl}
        />
      )}

      <PaginationButtons
        showPaginationButtons={showPaginationButtons}
        currentIndex={index}
        goToPane={goToPane}
        totalPages={panesAmount}
      />

      {showPageNumber && (
        <PanesAmount
          className={cx(hasDarkBackground && 'hasDarkBackground')}
        >{`${centerPaneIndex + 1}/${panesAmount}`}</PanesAmount>
      )}
    </Container>
  )
}
