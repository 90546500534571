import React, {useCallback, useMemo, useRef} from 'react'
import {usePinch} from '@use-gesture/react'
import {
  MotionValue,
  PanInfo,
  Transition,
  useAnimation,
  useDragControls
} from 'framer-motion'
import throttle from 'lodash/throttle'
import {once} from 'ramda'

import {PaneStyled, Pinchable} from './styled'

interface Props {
  index: number
  paneIndex: number
  isZoomEnabled: boolean
  renderPane: (paneIndex: number) => React.ReactNode
  x: MotionValue
  onDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void
  onZoom?: () => void
}

export type RenderPane = Props['renderPane']

const pinchConfig: Transition = {
  type: 'spring',
  mass: 0.5,
  stiffness: 220,
  damping: 14
}

type Point = [number, number]
const diff = (point1: Point, point2: Point): Point => [
  point1[0] - point2[0],
  point1[1] - point2[1]
]

export const Pane = ({
  index,
  paneIndex,
  isZoomEnabled,
  renderPane,
  x,
  onDragEnd,
  onZoom
}: Props) => {
  const child = React.useMemo(
    () => renderPane(paneIndex),
    [paneIndex, renderPane]
  )

  const controls = useDragControls()
  const allowDragRef = useRef<boolean>(true)
  const pinchRef = useRef<HTMLDivElement>(null)
  const animation = useAnimation()

  // Throttling the pinch handler causes it to lose it's `memo` state so we throttle the animation
  const animateStartThrottled = useMemo(
    () => throttle(animation.start, 50, {leading: false, trailing: true}),
    [animation]
  )

  const {current: trackZoomedOnce} = useRef(
    once(() => {
      if (onZoom) onZoom()
    })
  )

  usePinch(
    state => {
      if (!pinchRef.current) return

      const {pinching, origin, offset, first, last, memo} = state

      // Prevent the drag, enable is delayed to prevent jank (when one finger is always released earlier)
      if (first) {
        allowDragRef.current = false
      }

      if (last) {
        setTimeout(() => {
          allowDragRef.current = true
        }, 300)
      }

      const [scale] = offset

      const startingOrigin = first ? origin : memo
      const originOffset = diff(startingOrigin, origin)

      const x =
        (pinchRef.current.clientWidth / 2 - origin[0] - originOffset[0]) * scale
      const y =
        (pinchRef.current.clientHeight / 2 - origin[1] - originOffset[1]) *
        scale

      if (isZoomEnabled) trackZoomedOnce()

      animateStartThrottled(
        pinching
          ? {
              scale,
              x,
              y
            }
          : {
              scale: 1,
              x: 0,
              y: 0
            },
        pinchConfig
      )

      // Store origin in memo
      return startingOrigin
    },
    {
      target: pinchRef.current || undefined,
      from: [1, 0],
      enabled: isZoomEnabled
    }
  )

  const onDragStart = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if (!allowDragRef.current) {
        // @ts-expect-error - we're (ab)using an internal here, we should be very careful and try to remove this whenever we upgrade framer-motion
        controls.componentControls.forEach(entry => {
          // be sure to pass along the event & info or it gets angry
          entry.stop(event, info)
        })
      }
    },
    [controls]
  )

  return (
    <PaneStyled
      draggable
      drag="x"
      dragControls={controls}
      dragElastic={1}
      style={{
        x,
        left: `${index * 100}%`
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Pinchable ref={pinchRef} animate={animation}>
        {child}
      </Pinchable>
    </PaneStyled>
  )
}
