import {useEffect} from 'react'

import {Brand} from '@daedalus/core/src/brand/types'

type PropsType = {
  brand?: Brand
  languageCode: string
}

const FALLBACK_FONT_LANGUAGES = new Set([
  'ar',
  'he',
  'ja',
  'ko',
  'ru',
  'th',
  'uk',
  'zh-CN',
  'zh-HK',
  'zh-TW',
  'zh'
])

const fallbackFonts = `
  @font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: normal;
    src: local('.SFNSText-Regular'),
      local('.HelveticaNeueDeskInterface-Regular'), local('.LucidaGrandeUI'),
      local('Ubuntu Regular'), local('Segoe UI Regular'),
      local('Roboto-Regular'), local('DroidSans'), local('Tahoma');
  }

  @font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 500;
    src: local('.SFNSText-Medium'), local('.HelveticaNeueDeskInterface-Medium'),
      local('.LucidaGrandeUI'), local('Ubuntu Medium'), local('Segoe UI Medium'),
      local('Roboto-Medium'), local('DroidSans'), local('Tahoma');
  }

  @font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 600;
    src: local('.SFNSText-Semibold'),
      local('.HelveticaNeueDeskInterface-Semibold'), local('.LucidaGrandeUI'),
      local('Ubuntu Semibold'), local('Segoe UI Semibold'),
      local('Roboto-Semibold'), local('DroidSans'), local('Tahoma');
  }

  @font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 700;
    src: local('.SFNSText-Bold'), local('.HelveticaNeueDeskInterface-Bold'),
      local('.LucidaGrandeUI'), local('Ubuntu Bold'), local('Segoe UI Bold'),
      local('Roboto-Bold'), local('DroidSans'), local('Tahoma');
  }
`

const fontFaces = `
  @font-face {
    font-family: 'Circular';
    src: url('/assets/fonts/CircularXX-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/assets/fonts/CircularXX-Book.woff2') format('woff2');
    font-style: normal;
    font-weight: 450;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/assets/fonts/CircularXX-Medium.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
  }
`

export const getFontStyles = (languageCode: string) =>
  FALLBACK_FONT_LANGUAGES.has(languageCode) ? fallbackFonts : fontFaces

const FontsLoader = ({languageCode}: PropsType) => {
  useEffect(() => {
    const styles = getFontStyles(languageCode)
    const style = document.createElement('style')
    style.innerHTML = styles
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [languageCode])

  return null
}

export default FontsLoader
