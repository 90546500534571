import {
  BaseProps,
  PredefinedClassItem,
  PredefinedClassItemWithValue
} from '../../../../../types/Box'

export const BREAK_POINTS = [
  'mobileSm',
  'mobileLg',
  'desktopXs',
  'desktopSm',
  'desktopMd',
  'desktopLg'
]

export const SPACING_PROPERTIES: PredefinedClassItem[] = [
  {
    key: 'width',
    property: 'width'
  },
  {
    key: 'minWidth',
    property: 'min-width'
  },
  {
    key: 'maxWidth',
    property: 'max-width'
  },
  {
    key: 'height',
    property: 'height'
  },
  {
    key: 'minHeight',
    property: 'min-height'
  },
  {
    key: 'maxHeight',
    property: 'max-height'
  },
  {
    key: 'padding',
    property: 'padding'
  },
  {
    key: 'paddingBottom',
    property: 'padding-bottom'
  },
  {
    key: 'paddingLeft',
    property: 'padding-left'
  },
  {
    key: 'paddingRight',
    property: 'padding-right'
  },
  {
    key: 'paddingTop',
    property: 'padding-top'
  },
  {
    key: 'margin',
    property: 'margin'
  },
  {
    key: 'marginBottom',
    property: 'margin-bottom'
  },
  {
    key: 'marginLeft',
    property: 'margin-left'
  },
  {
    key: 'marginRight',
    property: 'margin-right'
  },
  {
    key: 'marginTop',
    property: 'margin-top'
  },
  {
    key: 'top',
    property: 'top'
  },
  {
    key: 'bottom',
    property: 'bottom'
  },
  {
    key: 'right',
    property: 'right'
  },
  {
    key: 'left',
    property: 'left'
  },
  {
    key: 'borderWidth',
    property: 'border-width'
  },
  {
    key: 'outlineWidth',
    property: 'outline-width'
  },
  {
    key: 'outlineOffset',
    property: 'outline-offset'
  },
  {
    key: 'flexBasis',
    property: 'flex-basis'
  }
]

export const COLOR_PROPERTIES: PredefinedClassItem[] = [
  {
    key: 'backgroundColor',
    property: 'background-color'
  },
  {
    key: 'color',
    property: 'color'
  },
  {
    key: 'borderColor',
    property: 'border-color'
  },
  {
    key: 'outlineColor',
    property: 'outline-color'
  }
]

export const STRING_PROPERTIES: PredefinedClassItem[] = [
  {
    key: 'gridColumn',
    property: 'grid-column'
  },
  {
    key: 'gridRow',
    property: 'grid-row'
  },
  {
    key: 'gridAutoRows',
    property: 'grid-auto-rows'
  },
  {
    key: 'gridTemplateColumns',
    property: 'grid-template-columns'
  },
  {
    key: 'zIndex',
    property: 'z-index'
  },
  {
    key: 'transition',
    property: 'transition'
  },
  {
    key: 'gap',
    property: 'gap'
  },
  {
    key: 'flexBasis',
    property: 'flex-basis'
  },
  {
    key: 'flex',
    property: 'flex'
  },
  {
    key: 'outlineOffset',
    property: 'outline-offset'
  },
  {
    key: 'outlineWidth',
    property: 'outline-width'
  }
]

export const PREDEFINED_CLASSES: PredefinedClassItemWithValue[] = [
  {
    key: 'display',
    property: 'display',
    values: [
      'inline',
      'block',
      'flex',
      'grid',
      'inline-block',
      'inline-flex',
      'inline-grid',
      'table',
      'none'
    ]
  },
  {
    key: 'position',
    property: 'position',
    values: [
      'static',
      'absolute',
      'fixed',
      'relative',
      'sticky',
      'initial',
      'inherit'
    ]
  },
  {
    key: 'whiteSpace',
    property: 'white-space',
    values: [
      'normal',
      'nowrap',
      'pre',
      'pre-wrap',
      'pre-line',
      'inherit',
      'initial',
      'unset'
    ]
  },
  {
    key: 'textAlign',
    property: 'text-align',
    values: ['left', 'right', 'center', 'justify']
  },
  {
    key: 'alignItems',
    property: 'align-items',
    values: ['flex-start', 'center', 'flex-end', 'stretch', 'baseline']
  },
  {
    key: 'justifyContent',
    property: 'justify-content',
    values: [
      'flex-start',
      'center',
      'flex-end',
      'space-between',
      'space-around',
      'space-evenly'
    ]
  },
  {
    key: 'flexDirection',
    property: 'flex-direction',
    values: ['row', 'row-reverse', 'column', 'column-reverse']
  },
  {
    key: 'flexWrap',
    property: 'flex-wrap',
    values: ['nowrap', 'wrap', 'wrap-reverse']
  },
  {
    key: 'alignSelf',
    property: 'align-self',
    values: ['auto', 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']
  },
  {
    key: 'alignContent',
    property: 'align-content',
    values: [
      'stretch',
      'center',
      'flex-start',
      'flex-end',
      'space-between',
      'space-around',
      'space-evenly'
    ]
  },
  {
    key: 'overflow',
    property: 'overflow',
    values: ['visible', 'hidden', 'scroll', 'auto']
  },
  {
    key: 'gridAutoFlow',
    property: 'grid-auto-flow',
    values: ['row', 'column']
  },
  {
    key: 'borderStyle',
    property: 'border-style',
    values: ['dotted', 'dashed', 'solid', 'none']
  },
  {
    key: 'outlineStyle',
    property: 'outline-style',
    values: ['dotted', 'dashed', 'solid', 'none']
  },
  {
    key: 'cursor',
    property: 'cursor',
    values: ['auto', 'none', 'pointer']
  },
  {
    key: 'boxSizing',
    property: 'box-sizing',
    values: ['content-box', 'border-box']
  }
]

export const SPACING_PROPERTIES_KEYS = SPACING_PROPERTIES.map(item => item.key)

export const BOX_CSS_PROPS_KEYS = new Set<keyof BaseProps>([
  ...SPACING_PROPERTIES_KEYS,
  ...COLOR_PROPERTIES.map(item => item.key),
  ...STRING_PROPERTIES.map(item => item.key),
  ...PREDEFINED_CLASSES.map(item => item.key),
  'borderRadius',
  'flexGrow',
  'shadow'
])
