import React, {
  cloneElement,
  ComponentType,
  ReactElement,
  useEffect
} from 'react'
import {useIntl} from 'react-intl'
import {
  FieldAttributes,
  FieldInputProps,
  FieldValidator,
  FormikContextType,
  FormikHelpers
} from 'formik'
import {path} from 'ramda'

import {
  ValidationKeys,
  validationMessages
} from '@daedalus/core/src/forms/messages/validationMessages'

import {InputSelect} from '../../InputSelect'
import {InputText} from '../../InputText'
import {InputTextarea} from '../../InputTextarea'

type InputElement = typeof InputSelect | typeof InputTextarea | typeof InputText

export type PropsType = {
  /** A single Input element */
  children: ReactElement<InputElement>
  /** Formik Field, used to match up an input with correct Formik state */
  Field: ComponentType<FieldAttributes<unknown>>
  /** Name that will be assigned to the input */
  name: string
  /** Validation to be applied to this input */
  validate?: FieldValidator
}

interface FormikWrapperProps {
  children: JSX.Element
  field: FieldInputProps<unknown[]>
  form: FormikContextType<unknown>
}

interface ChildProps extends FieldInputProps<unknown[]> {
  hasError: boolean
  isTouched?: string
  caption?: React.ReactNode
  setFieldValue: FormikHelpers<unknown>['setFieldValue']
}

const requiredFieldKey = validationMessages.requiredField.id

const FormikWrapper = ({
  children,
  field,
  form: {touched, errors, setFieldTouched, setFieldValue}
}: FormikWrapperProps) => {
  const {formatMessage} = useIntl()

  const fieldPath = path<string>(field.name.split('.'))
  const isTouched = fieldPath(touched)
  const fieldError = fieldPath(errors)
  const hasError = Boolean(fieldError)
  const errorMessage =
    fieldError &&
    fieldError !== requiredFieldKey &&
    formatMessage(validationMessages[fieldError as ValidationKeys], {
      break: () => <br />
    })

  useEffect(() => {
    // manually call setFieldTouched to validate that the initial value is set
    if (field.value && field.value.length > 0 && !isTouched) {
      setFieldTouched(field.name, true, true)
    }
  }, [])

  // TODO (Atlas): Look at removing the need to pass in isTouched here. Rather determine the validation here and pass it down to the child.
  const childProps: ChildProps = {
    hasError,
    isTouched,
    setFieldValue,
    ...field
  }

  if (hasError && isTouched) {
    childProps.caption = errorMessage
  }

  return cloneElement(children, childProps)
}

export const InputWrapperFormik = ({
  children,
  Field,
  name,
  validate
}: PropsType) => (
  <Field name={name} validate={validate}>
    {({field, form}: Omit<FormikWrapperProps, 'children'>) => (
      <FormikWrapper field={field} form={form}>
        {children}
      </FormikWrapper>
    )}
  </Field>
)
