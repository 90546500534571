import React from 'react'
import {Link} from 'react-router-dom'
import {cx} from '@linaria/core'

import {defaultBrand} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'
import urls from '@daedalus/core/src/utils/url'

import {Divider} from '../Divider'
import {Icon} from '../Icon'
import {
  BackButton,
  CenterComponentWrapper,
  HeaderBottomContent,
  HeaderElement,
  HeaderTopContent,
  LogoWrapper,
  RightComponentWrapper,
  StyledLogo
} from './styles'

type BrandLogoComponent = React.FunctionComponent<
  {brand: Brand} & React.ImgHTMLAttributes<HTMLImageElement>
>

interface Props {
  /** React component displayed at top central part of Header */
  CenterComponent?: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** React component displayed at top right part of Header */
  RightComponent?: React.ReactNode
  /** React component displayed at bottom part of Header */
  BottomComponent?: React.ReactNode
  /** Brand configuration. It can be one of our brands or a white-label brand. */
  brand?: Brand
  /** Whether to disable the click action in the logo */
  disableLogoClick?: boolean
  /** An action fired when the user clicks on the back button */
  onBackButtonClick?: () => void
  /** Whether to display the back button  */
  showBackButton?: boolean
  /** Whether to display the brand logotype  */
  showLogo?: boolean
  /** Which url should the logo send the user when clicked upon */
  logoUrl?: string
  /** Whether to display a bottom shadow */
  hasShadow?: boolean
  /** Whether to display a bottom divider */
  hasDivider?: boolean
  /** Whether to show the top header content */
  showTopContent?: boolean
  /** Whether the top header should take up less height */
  isCompact?: boolean
  /** React component displayed at top left part of Header */
  BrandLogoComponent: BrandLogoComponent
}

interface LogoType {
  disableLogoClick?: boolean
  brand: Brand
  logoUrl?: string
  BrandLogoComponent: BrandLogoComponent
}

const Logo = ({
  disableLogoClick,
  brand,
  logoUrl,
  BrandLogoComponent
}: LogoType) => {
  if (disableLogoClick) {
    return <BrandLogoComponent brand={brand} />
  }

  // some partners want a custom logo url, so we check for that first
  const redirectUrl = brand.siteAddresses.logoRedirectUrl || logoUrl

  if (brand.siteAddresses.logoRedirectUrl || logoUrl) {
    return (
      <StyledLogo data-id="BrandLogo" href={redirectUrl}>
        <BrandLogoComponent brand={brand} />
      </StyledLogo>
    )
  }

  return (
    <Link data-id="BrandLogo" to={urls.root}>
      <BrandLogoComponent brand={brand} />
    </Link>
  )
}

export const Header = ({
  brand = defaultBrand,
  CenterComponent,
  RightComponent,
  BottomComponent,
  showBackButton,
  showLogo = true,
  disableLogoClick,
  logoUrl,
  onBackButtonClick,
  hasShadow,
  hasDivider,
  className,
  showTopContent = true,
  isCompact = false,
  BrandLogoComponent
}: Props) => {
  const hasLeftContent = showBackButton || showLogo
  return (
    <HeaderElement className={cx(className, hasShadow && '--shadow')}>
      {showTopContent && (
        <HeaderTopContent
          className={cx(hasLeftContent && '--leftPadding')}
          isCompact={isCompact}
        >
          {hasLeftContent && (
            <LogoWrapper>
              {showBackButton && (
                <BackButton
                  hasTouchArea
                  size="md"
                  variant="quiet"
                  iconStart={<Icon name="ChevronLeft" />}
                  onClick={onBackButtonClick}
                />
              )}
              {showLogo && (
                <Logo
                  disableLogoClick={disableLogoClick}
                  brand={brand}
                  logoUrl={logoUrl}
                  BrandLogoComponent={BrandLogoComponent}
                />
              )}
            </LogoWrapper>
          )}
          <CenterComponentWrapper>
            {CenterComponent && CenterComponent}
          </CenterComponentWrapper>
          <RightComponentWrapper>
            {RightComponent && RightComponent}
          </RightComponentWrapper>
        </HeaderTopContent>
      )}
      {BottomComponent && (
        <HeaderBottomContent>{BottomComponent}</HeaderBottomContent>
      )}
      {hasDivider && <Divider />}
    </HeaderElement>
  )
}
