import React from 'react'

import {
  BaseProps,
  BoxCssProps,
  BoxNonCssProps,
  BoxProps,
  ColorRelatedProps,
  RadiusesPropsType,
  ShadowPropsType,
  SpacingPropsType
} from '../../../../../types/Box'
import {DeviceLayoutHookReturnType} from '../../../../context/deviceLayout'
import {cssTheme} from '../../../../themes'
import {Breakpoints} from '../../../../utils/breakpoints'
import {
  BOX_CSS_PROPS_KEYS,
  BREAK_POINTS,
  COLOR_PROPERTIES,
  PREDEFINED_CLASSES,
  SPACING_PROPERTIES,
  STRING_PROPERTIES
} from './constants'

export const getSpacingValue = (value?: string | SpacingPropsType): string => {
  if (!value) return ''
  if (value in cssTheme.layout.spacing) {
    return cssTheme.layout.spacing[value as SpacingPropsType]
  }
  return value as string
}

export const getSpacing = (
  props: BoxCssProps,
  name: keyof BaseProps,
  breakpoints?: Breakpoints
): string => {
  const spacingValue =
    breakpoints && props?.[breakpoints]?.[name] !== undefined
      ? props[breakpoints][name]
      : props[name]

  return getSpacingValue(spacingValue as string)
}

export const getBorderRadiusValue = (value: RadiusesPropsType): string => {
  if (!value) return ''
  return cssTheme.layout.radius[value]
}

export const getBorderRadius = (
  props: BoxCssProps,
  breakpoints?: Breakpoints
) => {
  const borderRadiusValue =
    breakpoints && props?.[breakpoints]?.borderRadius !== undefined
      ? props[breakpoints].borderRadius
      : props.borderRadius

  if (!borderRadiusValue) return ''
  return getBorderRadiusValue(borderRadiusValue)
}

export const getColor = (
  props: BoxCssProps,
  name: ColorRelatedProps,
  breakpoints?: Breakpoints
) => {
  const colorKey = breakpoints ? props?.[breakpoints]?.[name] : props?.[name]

  if (!colorKey) return undefined
  return (
    colorKey
      .split('.')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: any, part: string) => acc && acc[part], cssTheme.colors)
  )
}

export const getClasses = (
  props: BoxCssProps | undefined,
  breakpoints?: Breakpoints
): string[] => {
  const validClasses = new Set<string>()

  if (!props) return []

  Object.keys(props).forEach(key => {
    const typedKey = key as keyof typeof props

    if (props[typedKey] && !BREAK_POINTS.includes(typedKey)) {
      validClasses.add(
        `box-${typedKey}${breakpoints ? `-${props[typedKey]}-${breakpoints}` : `-${props[typedKey]}`}`
      )
    }
  })

  return [...validClasses]
}

const getPropertyStringValue = (
  props: BoxCssProps,
  name: keyof BaseProps,
  breakpoints?: Breakpoints
) => {
  if (breakpoints) return props?.[breakpoints]?.[name]
  return props?.[name]
}

export const getResponsiveClasses = (props: BoxCssProps): string[] => {
  return [
    ...getClasses(props),
    ...getClasses(props.desktopLg, Breakpoints.desktopLg),
    ...getClasses(props.desktopMd, Breakpoints.desktopMd),
    ...getClasses(props.desktopSm, Breakpoints.desktopSm),
    ...getClasses(props.desktopXs, Breakpoints.desktopXs),
    ...getClasses(props.mobileLg, Breakpoints.mobileLg),
    ...getClasses(props.mobileSm, Breakpoints.mobileSm)
  ]
}

const getIsPropValid = (
  props: BoxCssProps,
  propName: keyof BaseProps
): boolean => {
  if (
    props[propName] ||
    props?.mobileSm?.[propName] ||
    props?.mobileLg?.[propName] ||
    props?.desktopXs?.[propName] ||
    props?.desktopSm?.[propName] ||
    props?.desktopMd?.[propName] ||
    props?.desktopLg?.[propName]
  )
    return true
  return false
}

const getIsValidDynamicSpacingProp = (
  props: BoxCssProps,
  propName: keyof BaseProps
): boolean => {
  const value =
    props[propName] ||
    props?.mobileSm?.[propName] ||
    props?.mobileLg?.[propName] ||
    props?.desktopXs?.[propName] ||
    props?.desktopSm?.[propName] ||
    props?.desktopMd?.[propName] ||
    props?.desktopLg?.[propName]

  if (value && typeof value !== 'boolean') {
    // Skip inline css for predefined classes
    if (value in cssTheme.layout.spacing) return false
    return true
  }
  return false
}

const getValueBasedType = (
  props: BoxCssProps,
  propName: keyof BaseProps,
  breakpoint?: Breakpoints
) => {
  switch (propName) {
    case 'backgroundColor':
    case 'color':
    case 'borderColor':
    case 'outlineColor':
      return getColor(props, propName, breakpoint)
    case 'gridColumn':
    case 'gridRow':
    case 'gridAutoRows':
    case 'gridTemplateColumns':
    case 'zIndex':
    case 'transition':
    case 'flex':
    case 'outlineOffset':
    case 'outlineWidth':
      return getPropertyStringValue(props, propName, breakpoint)
    default:
      return getSpacing(props, propName, breakpoint)
  }
}

const getDynamicStyleValue = (
  props: BoxCssProps,
  propName: keyof BaseProps,
  layout: DeviceLayoutHookReturnType
): string => {
  let value: string = getValueBasedType(props, propName)
  if (layout.isMobile) {
    if (layout.isMobileLg && props?.mobileLg?.[propName]) {
      value = getValueBasedType(props, propName, Breakpoints.mobileLg)
    } else if (props?.mobileSm?.[propName]) {
      value = getValueBasedType(props, propName, Breakpoints.mobileSm)
    }
  } else if (layout.isDesktop) {
    if (layout.isDesktopLg && props?.desktopLg?.[propName]) {
      value = getValueBasedType(props, propName, Breakpoints.desktopLg)
    } else if (
      (layout.isDesktopMd || layout.isDesktopLg) &&
      props?.desktopMd?.[propName]
    ) {
      value = getValueBasedType(props, propName, Breakpoints.desktopMd)
    } else if (
      (layout.isDesktopSm || layout.isDesktopMd || layout.isDesktopLg) &&
      props?.desktopSm?.[propName]
    ) {
      value = getValueBasedType(props, propName, Breakpoints.desktopSm)
    } else if (props?.desktopXs?.[propName]) {
      value = getValueBasedType(props, propName, Breakpoints.desktopXs)
    }
  }
  return value
}

export const getSpacingDynamicStyles = (
  props: BoxCssProps,
  layout: DeviceLayoutHookReturnType
): React.CSSProperties => {
  return SPACING_PROPERTIES.map(i => i.key).reduce<React.CSSProperties>(
    (a, v) => ({
      ...a,
      ...(getIsValidDynamicSpacingProp(props, v) && {
        [v]: getDynamicStyleValue(props, v, layout)
      })
    }),
    {}
  )
}

export const getColorDynamicStyles = (
  props: BoxCssProps,
  layout: DeviceLayoutHookReturnType
): React.CSSProperties => {
  return COLOR_PROPERTIES.map(i => i.key).reduce<React.CSSProperties>(
    (a, v) => ({
      ...a,
      ...(getIsPropValid(props, v) && {
        [v]: getDynamicStyleValue(props, v, layout)
      })
    }),
    {}
  )
}

export const getStringDynamicStyles = (
  props: BoxCssProps,
  layout: DeviceLayoutHookReturnType
): React.CSSProperties => {
  return STRING_PROPERTIES.map(i => i.key).reduce<React.CSSProperties>(
    (a, v) => ({
      ...a,
      ...(getIsPropValid(props, v) && {
        [v]: getDynamicStyleValue(props, v, layout)
      })
    }),
    {}
  )
}

export const getDynamicStyles = (
  props: BoxCssProps,
  layout: DeviceLayoutHookReturnType
): React.CSSProperties => ({
  ...getSpacingDynamicStyles(props, layout),
  ...getColorDynamicStyles(props, layout),
  ...getStringDynamicStyles(props, layout)
})

export const getPredefinedClasses = (breakpoint?: Breakpoints): string => {
  return PREDEFINED_CLASSES.map(({key, property, values}) =>
    values.map(value => {
      return `
        &.box-${key}${breakpoint ? `-${value}-${breakpoint}` : `-${value}`} {
          ${property}: ${value};
        }
      `
    })
  ).join('\n')
}

export const getPredefinedShadowClasses = (
  breakpoint?: Breakpoints
): string => {
  return Object.keys(cssTheme.shadows)
    .map(key => {
      const shadow = key as ShadowPropsType
      return `
        &.box-shadow${breakpoint ? `-${shadow}-${breakpoint}` : `-${shadow}`} {
          box-shadow: ${cssTheme.shadows[shadow]};
        }
      `
    })
    .join('\n')
}

export const getPredefinedBorderRadiusClasses = (
  breakpoint?: Breakpoints
): string => {
  return Object.keys(cssTheme.layout.radius)
    .map(key => {
      const radius = key as RadiusesPropsType
      return `
        &.box-borderRadius${breakpoint ? `-${radius}-${breakpoint}` : `-${radius}`} {
          border-radius: ${cssTheme.layout.radius[radius]};
        }
      `
    })
    .join('\n')
}

export const getPredefinedSpacingPropertiesClasses = (
  breakpoint?: Breakpoints
): string => {
  return Object.keys(cssTheme.layout.spacing)
    .map(key => {
      const spacing = key as SpacingPropsType
      return `
        &.box-width${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          width: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-minWidth${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          min-width: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-maxWidth${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          max-width: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-height${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          height: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-minHeight${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          minHeight: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-maxHeight${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          max-height: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-padding${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          padding: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-paddingRight${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          padding-right: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-paddingLeft${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          padding-left: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-paddingTop${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          padding-top: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-paddingBottom${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          padding-bottom: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-margin${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          margin: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-marginRight${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          margin-right: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-marginLeft${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          margin-left: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-marginTop${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          margin-top: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-marginBottom${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          margin-bottom: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-right${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          right: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-left${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          left: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-top${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          top: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-bottom${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          bottom: ${cssTheme.layout.spacing[spacing]};
        } 
        &.box-borderWidth${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          border-width: ${cssTheme.layout.spacing[spacing]};
        } 
        &.box-outlineWidth${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          outline-width: ${cssTheme.layout.spacing[spacing]};
        } 
        &.box-outlineOffset${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          outline-offset: ${cssTheme.layout.spacing[spacing]};
        }
        &.box-flexBasis${breakpoint ? `-${spacing}-${breakpoint}` : `-${spacing}`} {
          flex-basis: ${cssTheme.layout.spacing[spacing]};
        }
      `
    })
    .join('\n')
}

export const getSplittedProps = (
  props: BoxProps
): {cssProps: BoxCssProps; baseProps: BoxNonCssProps} => {
  let cssProps: Partial<BoxCssProps> = {}
  let baseProps: BoxNonCssProps = {
    children: props.children,
    className: props.className,
    hasMirror: props.hasMirror
  }

  const {
    desktopLg,
    desktopMd,
    desktopSm,
    desktopXs,
    mobileLg,
    mobileSm,
    ...restProps
  } = props

  Object.keys(restProps).forEach(key => {
    if (BOX_CSS_PROPS_KEYS.has(key as keyof BaseProps)) {
      cssProps = {
        ...cssProps,
        [key]: restProps[key as keyof BaseProps]
      }
    } else {
      baseProps = {
        ...baseProps,
        [key]: restProps[key as keyof BoxNonCssProps]
      }
    }
  })

  return {
    cssProps: {
      desktopLg,
      desktopMd,
      desktopSm,
      desktopXs,
      mobileLg,
      mobileSm,
      ...cssProps
    },
    baseProps
  }
}
