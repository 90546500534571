import React, { ForwardedRef, forwardRef, PropsWithChildren, ReactElement, ReactNode, RefObject, useRef } from 'react';
import { cx } from '@linaria/core';
import noop from 'lodash/noop';
import { Button } from '../Button';
import { TitleBar, TitleBarProps, TitleBarVariant } from '../helpers/TitleBar';
import { Icon } from '../Icon';
import { ANIMATION_TYPES, AnimationType, Overlay } from '../Overlay';
import useBodyScrollLock from '../Overlay/useBodyScrollLock';
import { Header, OverlayBody, OverlayContent, OverlayFooter } from './styles';
interface Props {
  /** Whether the overlay is open */
  isOpen: boolean;
  /** The content of the overlay sub header */
  subHeaderContent?: ReactNode;
  /** The body of the overlay */
  children: ReactNode;
  /** The callback for closing the overlay  */
  onClose?: () => void;
  /** Whether to show the overlay close button */
  showCloseButton?: boolean;
  /** Toggle to show or hide the header box shadow */
  hasHeaderBoxShadow?: boolean;
  /** The style of animation for opening and closing */
  animationType?: AnimationType;
  /** Whether to add a shade to the background */
  hasShadeBackground?: boolean;
  /** Callback when user clicks on the background shadow */
  onShadeBackgroundClick?: () => void;
  /** All the props from the TitleBar can be applied here, check [TitleBar](/?path=/docs/helpers-titlebar--default) docs  */
  titleBarProps?: TitleBarProps;
  /** Determine the variant for the Header (using TitleBar underneath)  */
  headerVariant?: TitleBarVariant;
  /** Pass through classname to allow styles overrides */
  className?: string;
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string;
}
interface SimpleComponentProps {
  children: ReactNode;
  className?: string;
}
export const OverlayPageContent = forwardRef(({
  children,
  className
}: SimpleComponentProps, forwardedRef: ForwardedRef<HTMLDivElement>) => {
  const localRef = useRef<HTMLDivElement>();
  const ref = (forwardedRef || localRef) as RefObject<HTMLDivElement>; // Ref is optional but required from now on

  useBodyScrollLock({
    ref
  });
  return <OverlayContent ref={ref} className={className}>
        {children}
      </OverlayContent>;
});
OverlayPageContent.displayName = 'OverlayPageContent';
export const OverlayPageFooter = ({
  children,
  className
}: PropsWithChildren<{
  className?: string;
}>) => <OverlayFooter className={className}>{children}</OverlayFooter>;
const shadeBackgroundStyles = "sa7qn0h";
export const OverlayPage = ({
  isOpen,
  subHeaderContent,
  children,
  onClose,
  showCloseButton = true,
  hasHeaderBoxShadow = true,
  animationType = ANIMATION_TYPES.SLIDE_DOWN,
  hasShadeBackground = false,
  onShadeBackgroundClick = noop,
  titleBarProps = {},
  headerVariant,
  className,
  dataId
}: Props) => {
  const closeButton = <Button dataId="OverlayPageCloseButton" variant={headerVariant === 'inverse' ? 'transparent' : 'quiet'} size="lg" iconEnd={<Icon name="Close" colorPath={headerVariant === 'inverse' ? 'content.neutral.c000' : 'content.neutral.c950'} />} onClick={onClose} /> as ReactElement;
  return <Overlay shadeBackground={hasShadeBackground} animationType={animationType} isOpen={isOpen} onShadeBackgroundClick={onShadeBackgroundClick} enableBodyScrollLock={false} customStyles={hasShadeBackground ? shadeBackgroundStyles : undefined} onClose={onClose} className={className} dataId={dataId}>
      <OverlayBody>
        <Header className={cx(hasHeaderBoxShadow && '--shadow')}>
          <TitleBar endContent={showCloseButton ? closeButton : undefined} bottomContent={subHeaderContent} bottomContentPadding={false} variant={headerVariant} {...titleBarProps} />
        </Header>
        {children}
      </OverlayBody>
    </Overlay>;
};

require("./OverlayPage.wyw-in-js.css!=!../../../../../../node_modules/@wyw-in-js/webpack-loader/lib/outputCssLoader.js?cacheProvider=!./OverlayPage.tsx");