import React, {useCallback, useEffect, useRef, useState} from 'react'
import {cx} from '@linaria/core'

import {ImageProvider} from '@daedalus/core/src/staticContent/types/ImageProvider'

import {useDeviceLayout} from '../../../context/deviceLayout'
import {Icon} from '../Icon'
import {BorderRadius, Image, SIZES, SizeType} from '../Image'
import {
  baseArrowStyles,
  CenterClickArea,
  ImageContainer,
  ImageGallery,
  ImageGalleryMain,
  ImageRefContainer,
  ImagesNumber,
  ImagesWrapper,
  NextClickArea,
  PrevClickArea,
  StyledArrowLeft,
  StyledArrowRight,
  Thumb,
  ThumbsContainer,
  ThumbsWrapper
} from './style'

const IS_HORIZONTAL = false
const ITEM_SIZE = 50

interface Props {
  /** Imaging service for resizing */
  imageProvider: ImageProvider
  /** Apply specified border-radius styling. */
  borderRadius?: BorderRadius
  /** List of images to be displayed */
  images: string[]
  /** Size of the main image. Options defined by the Image component */
  mainImageSize?: SizeType
  /** Callback for when the displayed image is changed */
  onSelectedIndexChange?: (index: number) => void
  /** Callback for when the next button is clicked */
  onNextClick?: () => void
  /** Callback for when the next button is clicked */
  onPreviousClick?: () => void
  /** Callback for when the center of the main image is clicked */
  onCenterClick?: (index: number) => void
  /** Used to set the selected image */
  selectedIndex?: number
  /** Whether to show next/previous icons and the images count */
  showControls?: boolean
  /** Whether to show the image thumbnails */
  showThumbnails?: boolean
  /** List of thumbnails to be displayed */
  thumbnails: string[]
  /** Adapt styling for use in a wide layout */
  wideLayout?: boolean
  /** Pass through className to allow styles overrides */
  className?: string
}

export const Gallery: React.FC<Props> = ({
  imageProvider,
  borderRadius,
  images,
  mainImageSize = 'large',
  onSelectedIndexChange,
  onNextClick,
  onPreviousClick,
  onCenterClick,
  selectedIndex: propSelectedIndex = 0,
  showControls = true,
  showThumbnails = true,
  thumbnails,
  wideLayout = false,
  className
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(propSelectedIndex)
  const [offset, setOffset] = useState<number>(0)
  const [thumbsOffset, setThumbsOffset] = useState<number>(0)
  const {isDesktop} = useDeviceLayout()
  const size = images.length

  const refs = useRef<Record<string, HTMLDivElement | null>>({})

  const handleNextClick = useCallback(() => {
    const newIndex = selectedIndex < size - 1 ? selectedIndex + 1 : 0
    onNextClick?.()
    showImage(newIndex)
  }, [selectedIndex, size, onNextClick])

  const handlePrevClick = useCallback(
    (event?: React.SyntheticEvent) => {
      if (event) {
        event.stopPropagation()
      }

      const newIndex = selectedIndex > 0 ? selectedIndex - 1 : size - 1
      onPreviousClick?.()
      showImage(newIndex)
    },
    [selectedIndex, size, onPreviousClick]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') handlePrevClick()
      if (event.key === 'ArrowRight') handleNextClick()
    },
    [handleNextClick, handlePrevClick]
  )

  const handleThumbClick = useCallback((index: number) => {
    showImage(index)
  }, [])

  const showImage = (index: number) => {
    const selectedImage = refs.current[`image-${index}`]
    const newThumbsOffset = showThumbnail(index)

    if (onSelectedIndexChange) {
      onSelectedIndexChange(index)
    }
    if (!selectedImage) {
      setSelectedIndex(index)
      setThumbsOffset(newThumbsOffset)
      return null
    }

    const width = selectedImage.clientWidth
    const containerWidth = refs.current.container?.clientWidth
    let newOffset =
      -selectedImage.offsetLeft + (Number(containerWidth) - width) / 2

    if (index === size - 1) {
      newOffset = -selectedImage.offsetLeft + (Number(containerWidth) - width)
    }

    if (index === 0) newOffset = 0

    setOffset(newOffset)
    setSelectedIndex(index)
    setThumbsOffset(newThumbsOffset)
  }

  const showThumbnail = (index: number) => {
    if (IS_HORIZONTAL) return showNextThumbnail(index)
    return showNextRowOfThumbnails(index)
  }

  const showNextRowOfThumbnails = (index: number) => {
    const offset = refs.current[`thumb-${index}`]?.offsetTop
    const containerHeight = refs.current.thumbsContainer?.clientHeight
    if (offset && containerHeight && containerHeight < offset + ITEM_SIZE) {
      return containerHeight - offset - 2 * ITEM_SIZE
    }
    return 0
  }

  const showNextThumbnail = (index: number) => {
    const containerWidth = refs.current.thumbsContainer?.clientWidth
    const offset = refs.current[`thumb-${index}`]?.offsetLeft
    if (containerWidth && offset && containerWidth > offset + ITEM_SIZE)
      return 0
    if (index === size - 1) {
      return Number(containerWidth) - Number(offset) - ITEM_SIZE
    }
    if (containerWidth && offset && containerWidth < offset + ITEM_SIZE) {
      return containerWidth - offset - 2 * ITEM_SIZE
    }
    return 0
  }

  const offsetProp = IS_HORIZONTAL ? 'left' : 'top'

  const imagesToRender = selectedIndex > 0 ? images : images.slice(0, 2)

  useEffect(() => {
    if (typeof window !== 'undefined' && window !== null) {
      window.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (typeof window !== 'undefined' && window !== null) {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleKeyDown])

  // Update state when propSelectedIndex changes
  useEffect(() => {
    if (propSelectedIndex !== selectedIndex) {
      setSelectedIndex(propSelectedIndex)
    }
  }, [propSelectedIndex])

  return (
    <ImageGallery className={className}>
      <ImageGalleryMain
        ref={el => (refs.current.container = el as HTMLDivElement)}
        fullWidth={!showThumbnails}
        height={SIZES[mainImageSize as SizeType][1]}
        className={cx(wideLayout && '--wide')}
      >
        {showControls && (
          <>
            {onCenterClick && (
              <CenterClickArea onClick={() => onCenterClick(selectedIndex)} />
            )}
            <NextClickArea
              onClick={handleNextClick}
              hasCenterClick={Boolean(onCenterClick)}
            >
              <StyledArrowRight className={baseArrowStyles}>
                <Icon name="ChevronRight" size="lg" />
              </StyledArrowRight>
            </NextClickArea>
            <PrevClickArea
              onClick={handlePrevClick}
              hasCenterClick={Boolean(onCenterClick)}
            >
              <StyledArrowLeft className={baseArrowStyles}>
                <Icon name="ChevronLeft" size="lg" />
              </StyledArrowLeft>
            </PrevClickArea>
            <ImagesNumber>
              <Icon name="Camera" size="sm" /> {selectedIndex + 1}/{size}
            </ImagesNumber>
          </>
        )}
        <ImagesWrapper
          ref={el => (refs.current.imagesWrapper = el)}
          style={{[offsetProp]: offset + 'px'}}
        >
          {imagesToRender.map((image, index) => (
            <ImageRefContainer
              ref={el => (refs.current[`image-${index}`] = el)}
              key={index}
              selected={index === selectedIndex}
            >
              <ImageContainer>
                <Image
                  url={image}
                  size={mainImageSize}
                  imageProvider={imageProvider}
                  fullWidth={wideLayout}
                />
              </ImageContainer>
            </ImageRefContainer>
          ))}
        </ImagesWrapper>
      </ImageGalleryMain>
      <ThumbsContainer
        ref={el => (refs.current.thumbsContainer = el)}
        hidden={!showThumbnails}
        className={cx(wideLayout && '--wide')}
      >
        <ThumbsWrapper
          ref={el => (refs.current.thumbsWrapper = el)}
          style={{[offsetProp]: thumbsOffset + 'px'}}
        >
          {thumbnails.map((image, index) => (
            <Thumb
              ref={el => (refs.current[`thumb-${index}`] = el)}
              key={image}
              className={cx(index === selectedIndex && '--active')}
              onClick={() => handleThumbClick(index)}
            >
              <Image
                url={image}
                size={wideLayout && isDesktop ? 'medium' : 'small'}
                imageProvider={imageProvider}
                borderRadius={borderRadius}
              />
            </Thumb>
          ))}
        </ThumbsWrapper>
      </ThumbsContainer>
    </ImageGallery>
  )
}
