import React, {useMemo} from 'react'
import {cx} from '@linaria/core'

import {BoxProps} from '../../../../../types/Box'
import {useDeviceLayout} from '../../../../context/deviceLayout'
import {
  getDynamicStyles,
  getResponsiveClasses,
  getSplittedProps
} from './helpers'
import {Wrapper} from './styles'

export const Box: React.FC<BoxProps> = props => {
  const {
    cssProps,
    baseProps: {children, className, hasMirror, ...baseProps}
  } = getSplittedProps(props)

  const layouts = useDeviceLayout()

  const dynamicStyles = useMemo(
    () => getDynamicStyles(cssProps, layouts),
    [cssProps, layouts]
  )
  return (
    <Wrapper
      {...baseProps}
      className={cx(
        className,
        hasMirror && 'box-hasMirror',
        ...getResponsiveClasses(cssProps)
      )}
      style={dynamicStyles}
    >
      {children}
    </Wrapper>
  )
}
