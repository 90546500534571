import React, {useLayoutEffect, useRef, useState} from 'react'

import {RenderPane} from './Pane'
import {FullSizeWrapper} from './styled'
import {VirtualizedPane} from './VirtualizedPane'

interface Props {
  /** Total amount of panes, used for counter and positioning */
  panesAmount: number
  /** Pane renderer, render any content for the provided pane index */
  renderPane: RenderPane
  /** First pane to display after mount */
  initialPaneIndex: number
  /** Whether to show a gradient under the dots indicator */
  hasDarkGradient?: boolean
  /** Displays page number with shade background underneath */
  hasDarkBackground?: boolean
  /** Whether to enable pinch-zoom */
  isZoomEnabled?: boolean
  /** Whether we're in an RTL language */
  isRtl: boolean
  /** Show pane counter in the bottom right */
  showPageNumber?: boolean
  /** Show next/previous pane buttons */
  showPaginationButtons?: boolean
  /** Triggered whenever the user swipes the gallery */
  onSwipe?: (newPaneIndex: number) => void
  /** Triggered whenever the user zooms the gallery */
  onZoom?: () => void
  /** Handle overlay close */
  onClose: () => void
  /** Shows pagination buttons when pane is hovered */
  showPaginationOnHover?: boolean
}

const useParentWidth = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>()

  useLayoutEffect(() => {
    if (!ref.current) return

    setWidth(ref.current.offsetWidth)

    const observer = new ResizeObserver(e => {
      setWidth(e[0].contentBoxSize[0].inlineSize)
    })

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return {
    ref,
    width
  }
}

export const SwipePanes = ({
  panesAmount,
  renderPane,
  initialPaneIndex,
  showPageNumber = false,
  hasDarkGradient = true,
  hasDarkBackground = false,
  showPaginationButtons = false,
  isZoomEnabled = true,
  isRtl,
  onSwipe,
  onZoom,
  onClose,
  showPaginationOnHover = false
}: Props) => {
  const {width, ref} = useParentWidth()

  return (
    <FullSizeWrapper ref={ref}>
      {width ? (
        <VirtualizedPane
          initialIndex={initialPaneIndex}
          panesAmount={panesAmount}
          hasDarkGradient={hasDarkGradient}
          hasDarkBackground={hasDarkBackground}
          showPageNumber={showPageNumber}
          showPaginationButtons={showPaginationButtons}
          isZoomEnabled={isZoomEnabled}
          isRtl={isRtl}
          width={width}
          renderPane={renderPane}
          onSwipe={onSwipe}
          onZoom={onZoom}
          onClose={onClose}
          showPaginationOnHover={showPaginationOnHover}
        />
      ) : null}
    </FullSizeWrapper>
  )
}
